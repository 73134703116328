import React from "react";

function NotFound() {
    return (
        <div className="container text-center vertical-center-nf">
            <h1 className="text-center">
              Like a lot of things in life, this page could not, unfortunately; be found :(
            </h1>
        </div>
    );
}
export default NotFound;